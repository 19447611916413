export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_WITH_JWT = "LOGIN_USER_WITH_JWT";

export const FETCH_CURRENT_USER_DATA = "FETCH_CURRENT_USER_DATA";
export const FETCH_ALL_USERS_DATA = "FETCH_ALL_USERS_DATA";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_MODELS_DATA = "FETCH_USER_MODELS_DATA";
export const FETCH_USER_MODEL_DATA = "FETCH_USER_MODEL_DATA";

export const DELETE_USER_DATA = "DELETE_USER_DATA";
export const DELETE_MODEL_DATA = "DELETE_MODEL_DATA";

export const FETCH_FILE_TO_SEND = "FETCH_FILE_TO_SEND";
export const SEND_FILE = "SEND_FILE";

export const FETCH_IOS_FILE_TO_SEND = "FETCH_IOS_FILE_TO_SEND";
export const SEND_IOS_FILE = "SEND_IOS_FILE";

export const BUSY_DIALOG_SHOW = "BUSY_DIALOG_SHOW";
export const BUSY_DIALOG_HIDE = "BUSY_DIALOG_HIDE";

export const LOGIN_DIALOG_SHOW = "LOGIN_DIALOG_SHOW";
export const LOGIN_DIALOG_HIDE = "LOGIN_DIALOG_HIDE";

export const CHANGE_PASSWORD_DIALOG_SHOW = "CHANGE_PASSWORD_DIALOG_SHOW";
export const CHANGE_PASSWORD_DIALOG__HIDE = "CHANGE_PASSWORD_DIALOG__HIDE";

export const USER_MENU_SHOW = "USER_MENU_SHOW";
export const USER_MENU_HIDE = "USER_MENU_HIDE";

export const MAIN_MENU_SHOW = "MAIN_MENU_SHOW";
export const MAIN_MENU_HIDE = "MAIN_MENU_HIDE";

export const ADD_USER_DIALOG_SHOW = "ADD_USER_DIALOG_SHOW";
export const ADD_USER_DIALOG_HIDE = "ADD_USER_DIALOG_HIDE";

export const EDIT_USER_DIALOG_SHOW = "EDIT_USER_DIALOG_SHOW";
export const EDIT_USER_DIALOG_HIDE = "EDIT_USER_DIALOG_HIDE";

export const REMOVE_USER_DIALOG_SHOW = "REMOVE_USER_DIALOG_SHOW";
export const REMOVE_USER_DIALOG_HIDE = "REMOVE_USER_DIALOG_HIDE";

export const ADD_MODEL_DIALOG_SHOW = "ADD_MODEL_DIALOG_SHOW";
export const ADD_MODEL_DIALOG_HIDE = "ADD_MODEL_DIALOG_HIDE";

export const ADD_MODEL_DIALOG_SWITCH_TO_NEW_MODEL =
  "ADD_MODEL_DIALOG_SWITCH_TO_NEW_MODEL";
export const ADD_MODEL_DIALOG_SWITCH_TO_CLONE_MODEL =
  "ADD_MODEL_DIALOG_SWITCH_TO_CLONE_MODEL";

export const REMOVE_MODEL_DIALOG_SHOW = "REMOVE_MODEL_DIALOG_SHOW";
export const REMOVE_MODEL_DIALOG_HIDE = "REMOVE_MODEL_DIALOG_HIDE";

export const EDIT_MODEL_DIALOG_SHOW = "EDIT_MODEL_DIALOG_SHOW";
export const EDIT_MODEL_DIALOG_HIDE = "EDIT_MODEL_DIALOG_HIDE";

export const CHANGE_LANGUAGE_DIALOG_SHOW = "CHANGE_LANGUAGE_DIALOG_SHOW";
export const CHANGE_LANGUAGE_DIALOG_HIDE = "CHANGE_LANGUAGE_DIALOG_HIDE";

export const EDIT_DATA_COMPONENT_SELECT_USER =
  "EDIT_DATA_COMPONENT_SELECT_USER";
export const EDIT_DATA_COMPONENT_RESET = "EDIT_DATA_COMPONENT_RESET";
export const EDIT_DATA_COMPONENT_CHANGE_FILTER =
  "EDIT_DATA_COMPONENT_CHANGE_FILTER";
